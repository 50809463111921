import axios from "axios";
import qs from "querystring";
import { Notification, NotificationError } from "@/util/noty";
import { removePontuations, formatarNascimento } from "@/util/formatarPayload";

export default {
  state: {
    stateCotacoes: [],
    stateIdClienteCotacao: 0,
    stateParamsListarCotacoes: {},
  },
  getters: {
    stateCotacoes: (state) => state.stateCotacoes,
    stateIdClienteCotacao: (state) => state.stateIdClienteCotacao,
    stateParamsListarCotacoes: (state) => state.stateParamsListarCotacoes,
  },
  mutations: {
    _setCotacoes(state, data) {
      state.stateCotacoes = data;
    },
    _setIdClienteCotacao(state, data) {
      state.stateIdClienteCotacao = data;
    },
    _setParamsListarCotacoes(state, data) {
      state.stateParamsListarCotacoes = data;
    },
  },
  actions: {
    actionListarCotacoes({ commit }, data) {
      commit("setLoading", true);
      commit("_setParamsListarCotacoes", data);
      axios
        .get(
          `${process.env.VUE_APP_API}/cotacao-seguro/cotacoes?idCategoria=${data.idCategoria}&idStatus=${data.idStatus}`
        )
        .then((response) => {
          if (response.data.success) {
            commit("_setCotacoes", response.data.data.cotacoes);
            return response.data.data.cotacoes;
          } else {
            Notification(
              "danger",
              response.data.data.message.title,
              response.data.data.message.description
            );
          }
        })
        .catch((error) => {
          NotificationError();
          commit("setLoading", false);
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    actionCadastrarCliente({ commit }, data) {
      commit("setLoading", true);
      axios
        .post(
          `${process.env.VUE_APP_API}/cotacao-seguro/cadastrar-cliente`,
          qs.stringify({
            id_categoria: data.id_categoria,
            nome_cliente: data.nome_cliente,
            tipo_atendimento: data.tipo_atendimento,
            celular: removePontuations(data.celular),
          })
        )
        .then((response) => {
          if (response.data.success) {
            commit("_setIdClienteCotacao", response.data.data.clientId);
            return response.data.data.clientId;
          } else {
            Notification(
              "danger",
              response.data.data.message.title,
              response.data.data.message.description
            );
          }
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    actionSolicitarCotacao({ commit }, data) {
      commit("setLoading", true);
      axios
        .put(
          `${process.env.VUE_APP_API}/cotacao-seguro/solicitar-cotacao/${data.id_cliente}`,
          qs.stringify({
            id_cliente: data.id_cliente,
            id_categoria: data.id_categoria,
            nome_cliente: data.nome_cliente,
            tipo_atendimento: data.tipo_atendimento,
            celular: removePontuations(data.celular),
            id_marca: data.id_marca,
            id_modelo: data.id_modelo,
            ano_modelo: data.ano_modelo,
            bem: JSON.stringify(data.bem),
            placa: data.placa,
            chassi: data.chassi,
            tipo_utilizacao: data.tipo_utilizacao,
            possui_seguro: data.possui_seguro,
            id_seguradora: data.id_seguradora,
            classe_bonus: data.classe_bonus,
            houve_sinistro: data.houve_sinistro,
            cobertura_1825: data.cobertura_1825,
            cpf: removePontuations(data.cpf),
            nascimento: formatarNascimento(data.nascimento),
            cep: removePontuations(data.cep),
            id_estado_civil: data.id_estado_civil,
            anos_habilitacao: data.anos_habilitacao,
            email: data.email,
          })
        )
        .then((response) => {
          if (response.data.success) {
            commit("_setIdClienteCotacao", 0);
            Notification(
              "success",
              response.data.message.title,
              response.data.message.description
            );
            return response.data.data.clientId;
          } else {
            Notification(
              "danger",
              response.data.data.message.title,
              response.data.data.message.description
            );
          }
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    actionAtualizarStatusCotacao({ commit, dispatch }, data) {
      axios
        .put(
          `${process.env.VUE_APP_API}/cotacao-seguro/atualizar-status-cotacao/${data.id_cotacao}`,
          { id_status: data.id_status }
        )
        .then((response) => {
          if (response.data.success) {
            Notification(
              "success",
              response.data.message.title,
              response.data.message.description
            );
            dispatch(
              "actionListarCotacoes",
              this.getters.stateParamsListarCotacoes
            );
            return response.data.data.clientId;
          } else {
            Notification(
              "danger",
              response.data.data.message.title,
              response.data.data.message.description
            );
          }
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
  },
};
