import Vue from "vue";
import Antd from "ant-design-vue";
import router from "./router/router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { VueMaskDirective } from "v-mask";
import { VueperSlides, VueperSlide } from "vueperslides";
import VueSocialSharing from "vue-social-sharing";
import "vueperslides/dist/vueperslides.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "./App.vue";
import "ant-design-vue/dist/antd.css";
import store from "./store";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSocialSharing);
Vue.use(Antd);
Vue.directive("mask", VueMaskDirective);

// global style
require("../src/assets/css/style.css");

// Layouts
import Dashboard from "./components/layouts/dashboard/dashboard";
import Fullscreen from "./components/layouts/fullscreen/fullscreen";
import Website from "./components/layouts/website/website";
import Landing from "./components/layouts/landing/landing";
Vue.component("layout-dashboard", Dashboard);
Vue.component("layout-fullscreen", Fullscreen);
Vue.component("layout-website", Website);
Vue.component("layout-landing", Landing);
Vue.component("vueper-slides", VueperSlides);
Vue.component("vueper-slide", VueperSlide);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
