<template>
  <a-layout>
    <div
      class="container d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white"
    >
      <router-link to="/">
        <img :src="this.logoFestaSeguros" alt="" width="180" />
      </router-link>

      <div
        style="position: absolute; right: 20px; top: 50px"
        class="d-lg-none d-xl-none"
      >
        <a-icon type="menu" class="h4" @click="showDrawer" />
        <a-drawer
          placement="right"
          width="80%"
          :closable="false"
          :visible="visible"
          @close="onClose"
        >
          <img
            :src="this.logoFestaSeguros"
            alt=""
            width="130"
            @click="navigateTo('/')"
          />
          <div class="list-group list-group-flush mt-3">
            <a
              href="#"
              class="list-group-item list-group-item-action pl-0"
              @click="navigateTo('/contato')"
              >CONTATO</a
            >
          </div>
        </a-drawer>
      </div>
    </div>

    <a-layout-content>
      <div :style="{ minHeight: '380px' }">
        <slot />
      </div>
    </a-layout-content>
    <img :src="loadAsset('whatsapp.png')" width="50" class="align-wpp" />
    <div class="fixed-bottom text-center p-2 mt-4 bg-white">
      © Festa Seguros {{ year }} - Todos os direitos reservados
    </div>
  </a-layout>
</template>

<script>
import moment from "moment";
import router from "@/router/router";
import LogoFestaSeguros from "@/assets/img/logo-festa-seguros.png";
import { Constants } from "@/constants/constants";
export default {
  name: "app",
  data() {
    return {
      logoFestaSeguros: LogoFestaSeguros,
      constants: Constants,
      items: [],
      visible: false,
      year: moment().format("YYYY"),
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    navigateTo(to) {
      this.visible = false;
      router.push(to);
    },
    loadAsset(path) {
      return require(`@/assets/img/${path}`);
    },
  },
};
</script>
<style scoped>
.align-wpp {
  position: fixed;
  right: 20px;
  bottom: 50px;
}
</style>
