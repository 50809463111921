import axios from "axios";
import { NotificationError } from "@/util/noty";

export default {
  state: {
    stateMarcasCarros: [],
    stateMarcasCarrosModelos: [],
    stateMarcasCarrosModelosAno: [],
    stateMarcasCarrosModelosAnoDetalhe: [],
    stateMarcasMotos: [],
    stateMarcasMotosModelos: [],
    stateMarcasMotosModelosAno: [],
    stateMarcasMotosModelosAnoDetalhe: [],
    stateMarcasCaminhoes: [],
    stateMarcasCaminhoesModelos: [],
    stateMarcasCaminhoesModelosAno: [],
    stateMarcasCaminhoesModelosAnoDetalhe: [],
  },
  getters: {
    stateMarcasCarros: (state) => state.stateMarcasCarros,
    stateMarcasCarrosModelos: (state) => state.stateMarcasCarrosModelos,
    stateMarcasCarrosModelosAno: (state) => state.stateMarcasCarrosModelosAno,
    stateMarcasCarrosModelosAnoDetalhe: (state) =>
      state.stateMarcasCarrosModelosAnoDetalhe,
    stateMarcasMotos: (state) => state.stateMarcasMotos,
    stateMarcasMotosModelos: (state) => state.stateMarcasMotosModelos,
    stateMarcasMotosModelosAno: (state) => state.stateMarcasMotosModelosAno,
    stateMarcasMotosModelosAnoDetalhe: (state) =>
      state.stateMarcasMotosModelosAnoDetalhe,
    stateMarcasCaminhoes: (state) => state.stateMarcasCaminhoes,
    stateMarcasCaminhoesModelos: (state) => state.stateMarcasCaminhoesModelos,
    stateMarcasCaminhoesModelosAno: (state) =>
      state.stateMarcasCaminhoesModelosAno,
    stateMarcasCaminhoesModelosAnoDetalhe: (state) =>
      state.stateMarcasCaminhoesModelosAnoDetalhe,
  },
  mutations: {
    _setMarcaCarros(state, data) {
      state.stateMarcasCarros = data;
    },
    _setMarcaCarrosModelos(state, data) {
      state.stateMarcasCarrosModelos = data;
    },
    _setMarcaCarrosModelosAno(state, data) {
      state.stateMarcasCarrosModelosAno = data;
    },
    _setMarcaCarrosModelosAnoDetalhe(state, data) {
      state.stateMarcasCarrosModelosAnoDetalhe = data;
    },
    _setMarcaMotos(state, data) {
      state.stateMarcasMotos = data;
    },
    _setMarcaMotosModelos(state, data) {
      state.stateMarcasMotosModelos = data;
    },
    _setMarcaMotosModelosAno(state, data) {
      state.stateMarcasMotosModelosAno = data;
    },
    _setMarcaMotosModelosAnoDetalhe(state, data) {
      state.stateMarcasMotosModelosAnoDetalhe = data;
    },
    _setMarcaCaminhoes(state, data) {
      state.stateMarcasCaminhoes = data;
    },
    _setMarcaCaminhoesModelos(state, data) {
      state.stateMarcasCaminhoesModelos = data;
    },
    _setMarcaCaminhoesModelosAno(state, data) {
      state.stateMarcasCaminhoesModelosAno = data;
    },
    _setMarcaCaminhoesModelosAnoDetalhe(state, data) {
      state.stateMarcasCaminhoesModelosAnoDetalhe = data;
    },
  },
  actions: {
    actionListarMarcasCarros({ commit }) {
      axios
        .get(`${process.env.VUE_APP_API_FIPE}/carros/marcas.json`)
        .then((response) => {
          commit("_setMarcaCarros", response.data);
          return response.data;
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    actionListarMarcasCarrosModelos({ commit }, idMarca) {
      axios
        .get(`${process.env.VUE_APP_API_FIPE}/carros/veiculos/${idMarca}.json`)
        .then((response) => {
          commit("_setMarcaCarrosModelos", response.data);
          return response.data;
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    actionListarMarcasCarrosModelosAno({ commit }, data) {
      axios
        .get(
          `${process.env.VUE_APP_API_FIPE}/carros/veiculo/${data.idMarca}/${data.idModelo}.json`
        )
        .then((response) => {
          commit("_setMarcaCarrosModelosAno", response.data);
          return response.data;
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    actionListarMarcasCarrosModelosAnoDetalhe({ commit }, data) {
      return axios
        .get(
          `${process.env.VUE_APP_API_FIPE}/carros/veiculo/${data.idMarca}/${data.idModelo}/${data.anoModelo}.json`
        )
        .then((response) => {
          commit("_setMarcaCarrosModelosAnoDetalhe", response.data);
          return response.data;
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
  },
};
