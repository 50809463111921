import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/cotacoes",
      component: () => import("@/views/dashboard/cotacoes/cotacoes"),
      meta: {
        guest: false,
        layout: "layout-dashboard",
        sideBarMenu: true,
        pageName: "Cotações",
        pageIcon: "pie-chart",
      },
    },
    {
      path: "/",
      redirect: "/cotacoes",
      component: () => import("@/views/dashboard/cotacoes/cotacoes"),
      meta: {
        guest: false,
        layout: "layout-dashboard",
        sideBarMenu: false,
        pageName: "Login",
        pageIcon: "lock",
      },
    },
  ],
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
